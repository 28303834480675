@use '../../../styles/_global-variables.scss' as global;

.wrapper {
    display: inline-grid;
    grid-template-rows: auto 1fr;
    position: relative;
}

.drowpdown_icona {
    opacity: 0.8;
    width: max-content;
}

.dropWrapper {
    position: absolute;
    min-width: 100%;
    top: 36px;
    z-index: 11;
    display: none;
    border-left: 1px solid var(--clr_trans_60);
    border-right: 1px solid var(--clr_trans_60);
    border-bottom: 1px solid var(--clr_trans_60);
    max-height: 234px;
    overflow: auto;
    //Estils scrollbar Firefox
    scrollbar-color: var(--clr_trans_40) var(--clr_trans_70);
    scrollbar-width: thin;
    //Estils scrollbar Chrome
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: var(--clr_trans_70);
    }
    &::-webkit-scrollbar-thumb {
        background: var(--clr_trans_40);
    }
}

.open .dropWrapper {
    display: block;
}

.open .drowpdown_icona {
    transform: rotate(180deg);
}

.dropdown {
    display: flex;
    border: 1px solid var(--clr_trans_60);
    padding: 8px 10px 8px 8px;
    align-items: center;
    height: 36px;
    line-height: 1;
    background: global.$color95;
    z-index: 0;
    cursor: pointer;

    .open & {
        z-index: 3;
    }

    &:hover .drowpdown_icona {
        opacity: 1;
    }
}

.dropdown_text {
    padding-right: 10px;
    white-space: nowrap;
}

.combo {
    margin-block: 0;
}

.item {
    cursor: pointer;
    font-size: var(--body_m);
    background: global.$color90;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 18px 8px 8px;
    border-bottom: 1px solid var(--clr_trans_60);
    white-space: nowrap;
    &:hover {
        background: var(--clr_trans_50);
    }
    &.selected {
        background: var(--clr_trans_80);
    }
    &:last-child {
        border-bottom: none;
    }
}

@media #{global.$VP_BEYOND_MOBILE} {
    .dropdown {
        width: auto;
    }
}
