@use '../../../styles/_global-variables.scss' as global;

.dropdown,
.mosaic {
    padding: 0 0 16px;
}
.dropdown {
    padding-bottom: 30px;
}

.mosaicNoPadding {
    padding: 0 0px;
}

.titolCombo {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .dropdown {
        padding-bottom: 0px;
    }

    .item {
        background-color: #000;
        &.active,
        &.itemActive {
            background: global.$color80;
        }
        &:hover,
        &:active,
        &:focus {
            background: global.$color50;
        }
    }

    .linktitol {
        margin-bottom: -2px;
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }
}
.flexColumn {
    flex-direction: column;
    align-items: start;
    .titol {
        margin-bottom: 8px;
    }
}
.titol {
    font-weight: 300;
    text-transform: uppercase;
    font-size: var(--headline_xs);
    border-bottom: 1px solid transparent;

    &.titolHover:hover {
        border-bottom: 1px solid currentColor;
    }
}

.titolNoLink {
    font-weight: 300;
    text-transform: uppercase;
    font-size: var(--headline_xs);
    margin-right: 26px;
    border-bottom: 1px solid transparent;
}

.btnMoreItemsContent {
    text-align: center;
    margin-bottom: var(--gapRow);
    margin-top: calc(8px - var(--gapRow));
}
